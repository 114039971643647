import React from 'react';
import { useNavigate } from 'react-router-dom';

import { is } from '@onesy/utils';
import { Expand, Line, Tab, Tabs, Type, useLocation, useSubscription } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';

import IconMaterialGroup from '@onesy/icons-material-rounded-react/IconMaterialGroupW100';
import IconMaterialViewColumn from '@onesy/icons-material-rounded-react/IconMaterialViewColumnW100';
import IconMaterialCalendarMonth from '@onesy/icons-material-rounded-react/IconMaterialCalendarMonthW100';
import IconMaterialNote from '@onesy/icons-material-rounded-react/IconMaterialNoteW100';
import IconMaterialPerson from '@onesy/icons-material-rounded-react/IconMaterialPersonW100';
import IconMaterialWbSunny from '@onesy/icons-material-rounded-react/IconMaterialWbSunnyW100';
import IconMaterialWbTwilight from '@onesy/icons-material-rounded-react/IconMaterialWbTwilightW100';
import IconMaterialCounter7 from '@onesy/icons-material-rounded-react/IconMaterialCounter7W100';
import IconMaterialTaskAlt from '@onesy/icons-material-rounded-react/IconMaterialTaskAltW100';
import IconMaterialNotifications from '@onesy/icons-material-rounded-react/IconMaterialNotificationsW100';
import IconMaterialLock from '@onesy/icons-material-rounded-react/IconMaterialLockW100';
import IconMaterialFileUpload from '@onesy/icons-material-rounded-react/IconMaterialFileUploadW100';
import IconMaterialImagesmode from '@onesy/icons-material-rounded-react/IconMaterialImagesmodeW100';
import IconMaterialAccountCircle from '@onesy/icons-material-rounded-react/IconMaterialAccountCircleW100';
import IconMaterialIntegrationInstructions from '@onesy/icons-material-rounded-react/IconMaterialIntegrationInstructionsW100';
import IconMaterialAccountBalanceWallet from '@onesy/icons-material-rounded-react/IconMaterialAccountBalanceWalletW100';
import IconMaterialPersonFilled from '@onesy/icons-material-rounded-react/IconMaterialPersonFilledW100';
import IconMaterialStore from '@onesy/icons-material-rounded-react/IconMaterialStoreW100';
import IconMaterialDraw from '@onesy/icons-material-rounded-react/IconMaterialDrawW100';
import IconMaterialDrawCollage from '@onesy/icons-material-rounded-react/IconMaterialDrawCollageW100';

import { ReactComponent as Logo } from 'assets/svg/logo.svg';

import { AppsMenu, NotificationsMenu, UserMenu } from 'ui';
import { AuthService } from 'services';
import { getRootPage, isHex } from 'utils';
import { ISignedIn } from 'types';

const useStyle = style(theme => ({
  root: {
    flex: '0 0 auto',
    zIndex: 14
  },

  header: {
    position: 'relative',
    padding: 16,
    flex: '0 0 auto'
  },

  logo: {
    '& > svg': {
      height: 44,
      width: 'auto',
      cursor: 'pointer',
      userSelect: 'none',
      transition: theme.methods.transitions.make('transform', { duration: 'xs' }),

      '&:active': {
        transform: 'scale(0.94)'
      }
    }
  },

  tabs: {
    '& .onesy-Tabs-line': {
      height: 2
    }
  },

  tab: {
    '&.onesy-Tab-root': {
      padding: '8px 20px'
    }
  }
}), { name: 'onesy-Header' });

const Header = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const navigate = useNavigate();
  const location = useLocation();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [page, setPage] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [subRoutes, setSubRoutes] = React.useState([]);

  const refs = {
    root: React.useRef<any>(undefined)
  };

  const user = signedIn?.user;

  const isUser = user?.is?.user;

  const routes = React.useMemo(() => {

    return {
      tasks: isUser ? [
        { name: 'Today', to: '/tasks', Icon: IconMaterialWbSunny },
        { name: 'Tomorrow', to: '/tasks/tomorrow', Icon: IconMaterialWbTwilight },
        { name: 'Week', to: '/tasks/week', Icon: IconMaterialCounter7 },
        { name: 'All', to: '/tasks/all', Icon: IconMaterialTaskAlt },
        { name: 'Calendar', to: '/tasks/calendar', Icon: IconMaterialCalendarMonth },
        { name: 'Assigned to me', to: '/tasks/assigned-to-me', Icon: IconMaterialPerson },
        { name: 'Lists', to: '/tasks/lists', Icon: IconMaterialViewColumn }
      ] : [],

      notes: isUser ? [
        { name: 'My notes', to: '/notes', Icon: IconMaterialNote },
        { name: 'Mentions', to: '/notes/mentions', Icon: IconMaterialPerson }
      ] : [],

      library: [
        { name: 'Library', to: '/library', Icon: IconMaterialImagesmode },
        { name: 'Upload', to: '/library/upload', Icon: IconMaterialFileUpload }
      ],

      settings: [
        { name: 'Profile', to: '/settings', Icon: IconMaterialAccountCircle },
        { name: 'Security', to: '/settings/security', Icon: IconMaterialLock },
        { name: 'Notifications', to: '/settings/notifications', Icon: IconMaterialNotifications }
      ],

      organization_settings: [
        { name: 'Organization', to: '/organization/settings/info', Icon: IconMaterialStore },
        { name: 'Employees', to: '/organization/settings/employees', Icon: IconMaterialPersonFilled },
        { name: 'Employee groups', to: '/organization/settings/employee-groups', Icon: IconMaterialGroup },
        { name: 'Subscription', to: '/organization/settings/subscription', Icon: IconMaterialAccountBalanceWallet },
        { name: 'Integrations', to: '/organization/settings/integrations', Icon: IconMaterialIntegrationInstructions }
      ],

      boards: [
        { name: 'My boards', to: '/boards', Icon: IconMaterialDraw },
        { name: 'Shared boards', to: '/boards/shared', Icon: IconMaterialDrawCollage }
      ]
    };
  }, [signedIn]);

  const routesMap = React.useMemo(() => {
    const map = {};

    Object.keys(routes).forEach(key => {
      map[key.replace('/', '')] = key;

      routes[key].forEach(item => map[item.to.replace('/', '')] = key);
    });

    return map;
  }, [routes]);

  React.useEffect(() => {
    const pathname = location.pathname;

    let page_ = pathname.split('/').filter(Boolean)[0];

    if (pathname.startsWith('/organization/settings')) page_ = 'organization_settings';

    const pageRoute = routesMap[page_];

    const menus = routes[pageRoute];

    if (menus?.length) setSubRoutes(menus);

    setPage(page_);
  }, [routesMap, location]);

  const isActive = React.useCallback((pathname: string, route: string) => {
    const end = pathname.replace(`${route}/`, '');

    return !!(
      pathname === route ||
      (pathname.startsWith(route) && (end === 'add' || isHex(`0x${end}`)))
    );
  }, []);

  React.useEffect(() => {
    const pageRoute = routesMap[page];

    const menus = routes[pageRoute];

    setOpen(!!menus?.length);
  }, [page]);

  const iconProps = {
    size: 'large'
  };

  return (
    <Line
      ref={(item: any) => {
        refs.root.current = item;

        if (ref) {
          if (is('function', ref)) ref(item)
          else ref.current = item;
        }
      }}

      gap={0}

      direction='column'

      justify='unset'

      align='unset'

      fullWidth

      className={classNames([
        className,
        classes.root
      ])}

      {...other}
    >
      {/* Header */}
      <Line
        gap={2}

        direction='row'

        justify='space-between'

        align='center'

        fullWidth

        className={classNames([
          classes.header
        ])}
      >
        <Line
          gap={2}

          direction='row'

          align='center'
        >
          <Line
            className={classes.logo}
          >
            <Logo
              onClick={() => navigate(getRootPage(signedIn))}
            />
          </Line>
        </Line>

        <Line
          gap={1.5}

          direction='row'

          align='center'
        >
          <AppsMenu />

          <NotificationsMenu />

          <UserMenu />
        </Line>
      </Line>

      {/* Page menus */}
      <Expand
        in={open}
      >
        <Line
          gap={0}

          direction='row'

          justify='unset'

          align='center'

          fullWidth
        >
          <Tabs
            value={location.pathname}

            isActive={isActive}

            size='large'

            noDivider

            className={classes.tabs}
          >
            {subRoutes?.map((item: any) => (
              <Tab
                key={item.to}

                onClick={() => navigate(item.to)}

                value={item.to}

                icon={item.Icon ? <item.Icon {...iconProps} /> : null}

                className={classes.tab}
              >
                <Type
                  version='t2'
                >
                  {item.name}
                </Type>
              </Tab>
            ))}
          </Tabs>
        </Line>
      </Expand>
    </Line>
  );
});

export default Header;
