import React from 'react';

import { Tooltip, useConfirm, useSnackbars } from '@onesy/ui-react';
import { IBaseElement } from '@onesy/ui-react/types';
import { IPage } from '@onesy/api';

import IconMaterialHome from '@onesy/icons-material-rounded-react/IconMaterialHomeW100';
import IconMaterialWebStories from '@onesy/icons-material-rounded-react/IconMaterialWebStoriesW100';

import { FormPage, ItemCard, ItemMenu } from 'ui';
import { AppService, PageService, WebsiteService } from 'services';
import { getDate, getErrorMessage } from 'utils';

export interface IPageItem extends IBaseElement {
  object: IPage;
}

const Element: React.FC<IPageItem> = React.forwardRef((props, ref: any) => {
  const {
    website,

    object,

    ...other
  } = props;

  const confirm = useConfirm();
  const snackbars = useSnackbars();

  const onOpen = React.useCallback(() => {
    AppService.pages.addSecondary.emit({
      open: true,

      to: object.id,

      fullScreen: true,

      secondary: true,

      children: (
        <FormPage
          object={object}

          parent={website}

          onConfirm={() => WebsiteService.queryWebsitePages.value!.refetch()}
        />
      )
    });
  }, [object]);

  const onActive = React.useCallback(async (value: boolean = object.active) => {
    const confirmed = await confirm.open({
      name: value ? 'Making page private' : 'Publishing page'
    });

    if (!confirmed) return;

    const result = await PageService.update(object.id, {
      active: !value
    });

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: value ? 'Page made private' : 'Page published'
      });
    }

    // refresh 
    WebsiteService.queryWebsitePages.value!.refetch();
  }, [object]);

  const onRemove = React.useCallback(async () => {
    const confirmed = await confirm.open({
      name: `Removing ${object.name} page`
    });

    if (!confirmed) return;

    const result = await PageService.remove(object.id);

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `${object.name} page removed`
      });
    }

    // refresh 
    WebsiteService.queryWebsitePages.value!.refetch();
  }, [object]);

  return (
    <ItemCard
      ref={ref}

      items_start={[
        {
          icon: IconMaterialWebStories,
          onClick: onOpen
        },
        {
          name: 'Name',
          value: object.name,
          onClick: onOpen
        },
        {
          name: 'Route',
          value: object.home ? '/' : object.url?.startsWith('/') ? object.url : `/${object.url}`
        }
      ]}

      items_end={[
        ...(object.home ? [
          {
            value: (
              <Tooltip
                name='Home page'
              >
                <span>
                  <IconMaterialHome />
                </span>
              </Tooltip>
            )
          }
        ] : []),
        {
          name: 'Published',
          value: object?.active ? 'Yes' : 'No',
          propsValue: {
            color: object?.active ? 'success' : 'warning'
          }
        },
        {
          name: 'Added at',
          value: getDate(object?.added_at!),
          align: 'flex-end',
          propsValue: {
            whiteSpace: 'nowrap'
          }
        },
        {
          value: (
            <ItemMenu
              items={[
                { name: 'Open', onClick: onOpen },

                ...(!object.home ? [
                  { name: object.active ? 'Private' : 'Publish', onClick: () => onActive() },
                  { name: 'Remove', onClick: onRemove, props: { color: 'error' } }
                ] : [])
              ]}
            />
          )
        }
      ]}

      {...other}
    />
  );
});

export default Element; 