import React from 'react';

import { useSubscription } from '@onesy/ui-react';
import { classNames } from '@onesy/style-react';

import { FormUserAdd, FormUserUpdate, Items, NoResults, Page } from 'ui';
import { AuthService, OrganizationService } from 'services';
import ItemEmployee from './Item';
import { ISignedIn } from 'types';

const Element = React.forwardRef(() => {
  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const organization = signedIn.organization;

  const search: any = [
    {
      name: 'Name',
      type: 'text',
      property: 'name'
    }
  ];

  const queryObjectsName = 'queryUsers';

  const selectObjectsName = 'selectedUsers';

  return (
    <Page
      name='Employees'

      singular='employee'

      plural='employees'

      service={OrganizationService}

      queryObjectsName={queryObjectsName}

      selectObjectsName={selectObjectsName}

      getMethodName='getUser'

      search={search}

      FormAdd={FormUserAdd}

      FormUpdate={FormUserUpdate}

      className={classNames([
        'onesy-Employees'
      ])}
    >
      {({ response }) => {
        if (!response?.length) return <NoResults />;

        // sort 
        response.sort((a, b) => {
          const userOrganizationA = a?.organizations?.find((item: any) => item.id === organization.id);
          const userOrganizationB = b?.organizations?.find((item: any) => item.id === organization.id);

          return userOrganizationB.added_at - userOrganizationA.added_at;
        });

        return (
          <Items
            service={OrganizationService}

            queryObjectsName={queryObjectsName}

            selectObjectsName={selectObjectsName}
          >
            {response?.map((item: any, index: number) => (
              <ItemEmployee
                key={item.id}

                object={item}
              />
            ))}
          </Items>
        );
      }}
    </Page>
  );
});

export default Element;
