import React from 'react';
import { Excalidraw, MainMenu } from '@excalidraw/excalidraw';
import { AppState, BinaryFiles, ExcalidrawProps } from '@excalidraw/excalidraw/types/types';
import { ExcalidrawElement } from '@excalidraw/excalidraw/types/element/types';

export interface IWhiteboardData {
  id: string;
  elements: ExcalidrawElement[];
  appState: AppState;
  files: BinaryFiles;
  collaborator?: any;
}

const Whiteboard: React.FC<ExcalidrawProps & { className?: string; }> = React.forwardRef(props => {
  return (
    <Excalidraw
      {...props}
    >
      <MainMenu>
        <MainMenu.DefaultItems.LoadScene />
        <MainMenu.DefaultItems.SaveAsImage />
        <MainMenu.DefaultItems.Export />
        <MainMenu.DefaultItems.SaveToActiveFile />
        <MainMenu.DefaultItems.ClearCanvas />
        <MainMenu.DefaultItems.ToggleTheme />
        <MainMenu.DefaultItems.Help />
        <MainMenu.DefaultItems.ChangeCanvasBackground />
      </MainMenu>
    </Excalidraw>
  );
});

export default Whiteboard;
