import React from 'react';

import { IconButton, TextField, Tooltip } from '@onesy/ui-react';
import { ITextField } from '@onesy/ui-react/TextField/TextField';
import { classNames, style } from '@onesy/style-react';

import IconMaterialPassword2 from '@onesy/icons-material-rounded-react/IconMaterialPassword2W100';
import IconMaterialPassword2Off from '@onesy/icons-material-rounded-react/IconMaterialPassword2OffW100';
import IconMaterialClose from '@onesy/icons-material-rounded-react/IconMaterialCloseW100';

const useStyle = style(theme => ({
  root: {
    '& .onesy-TextField-icon-end': {
      paddingBlock: 0,
      paddingTop: 8
    }
  }
}), { name: 'onesy-TextField' });

const Element: React.FC<ITextField> = React.forwardRef((props: ITextField, ref: any) => {
  const {
    valueDefault,

    value: value_,

    onChange: onChange_,

    end,

    className,

    ...other
  } = props;

  const { classes } = useStyle(props);

  const [value, setValue] = React.useState(valueDefault !== undefined ? valueDefault : value_);
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (value_ !== undefined && value !== value_) setValue(value_);
  }, [value, value_]);

  const onChange = React.useCallback((value: any) => {
    setValue(value);

    if (onChange_) onChange_(value);
  }, [onChange_]);

  const toggleVisible = React.useCallback(() => {
    setVisible((item: any) => !item);
  }, []);

  const password = other.type === 'password';

  const iconProps: any = {
    size: 'regular'
  };

  const iconButtonPassword = (
    <Tooltip
      name={visible ? 'Hide password' : 'View password'}

      color='inverted'
    >
      <IconButton
        onClick={toggleVisible}

        color='default'

        size='small'
      >
        {visible ? <IconMaterialPassword2Off {...iconProps} /> : <IconMaterialPassword2 {...iconProps} />}
      </IconButton>
    </Tooltip>
  );

  return (
    <TextField
      ref={ref}

      version='outlined'

      valueDefault={valueDefault}

      value={value}

      end={end}

      endVerticalAlign='center'

      onChange={onChange}

      size='small'

      IconClear={IconMaterialClose}

      IconProps={{
        size: 'large'
      }}

      className={classNames([
        className,
        classes.root
      ])}

      {...other}

      {...(password && {
        type: visible ? 'text' : 'password',

        end: [
          ...(end as any || []),

          ...(value ? [iconButtonPassword] : [])
        ]
      })}
    />
  );
});

Element.displayName = 'onesy-TextField';

export default Element;
