import { Media } from '@onesy/api';
import OnesySubscription from '@onesy/subscription';
import { IAdditional } from '@onesy/sdk/other';

import { BaseService } from './base';
import { MainService } from 'services';
import { IQuerySubscription, ISelectedSubscription } from 'types';

class MediaService extends BaseService<Media> {
  public queryObjectsModal = new OnesySubscription<IQuerySubscription>();
  public selectObjectModal = new OnesySubscription<ISelectedSubscription>();

  public constructor() {
    super('medias');

    this.queryObjectsInit(this.queryObjectsModal);

    this.selectedObjectsInit(this.selectObjectModal);
  }

  public async add(body: any, options?: IAdditional) {
    if (!body.apps) body.apps = ['onesy'];

    const response = await MainService.sdk[this.route as 'medias']?.add(body as any, options);

    return response;
  }

}

export default new MediaService();
