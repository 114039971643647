import React from 'react';

import { textToInnerHTML } from '@onesy/utils';
import { IconButton, Line, SmartTextField, Switch, Tooltip } from '@onesy/ui-react';
import { IWebsiteButton } from '@onesy/api';

import IconMaterialRemove from '@onesy/icons-material-rounded-react/IconMaterialRemoveW100';
import IconMaterialKeyboardArrowDown from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowDownW100';
import IconMaterialKeyboardArrowUp from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowUpW100';
import IconMaterialWebStories from '@onesy/icons-material-rounded-react/IconMaterialWebStoriesW100';
import IconMaterialPhoneEnabled from '@onesy/icons-material-rounded-react/IconMaterialPhoneEnabledW100';
import IconMaterialMail from '@onesy/icons-material-rounded-react/IconMaterialMailW100';
import IconMaterialLink from '@onesy/icons-material-rounded-react/IconMaterialLinkW100';
import IconMaterialShortText from '@onesy/icons-material-rounded-react/IconMaterialShortTextW100';

import { ItemCard, Select } from 'ui/element';

export interface IAction {
  item: IWebsiteButton;

  menu?: any[];

  form: any;

  prefix: string;

  property?: string | number;

  index?: number;

  important?: boolean;

  onMove?: (indexPrevious: number, indexNew: number) => any;

  onRemove?: (index: number) => any;
}

const Element: React.FC<IAction> = React.forwardRef((props, ref: any) => {
  const {
    item = {
      version: 'to'
    },

    menu,

    form,

    prefix,

    property,

    index,

    important,

    onMove,

    onRemove
  } = props;

  const iconProps: any = {
    size: 'large'
  };

  const version = item.version || 'to';

  return (
    <ItemCard
      ref={ref}

      items_start={[
        {
          icon: version === 'text' ? IconMaterialShortText : version === 'link' ? IconMaterialLink : version === 'to' ? IconMaterialWebStories : version === 'email' ? IconMaterialMail : (item as any).version === 'phone' ? IconMaterialPhoneEnabled : undefined
        },
        {
          name: 'Name',
          value: (
            <SmartTextField
              placeholder='Name'

              value={textToInnerHTML(item.name || '')}

              onChange={(valueNew: any) => form.onChange(prefix, textToInnerHTML(valueNew), `${property !== undefined ? `${property}.` : ''}name`)}

              additional={{
                version: 'l1'
              }}
            />
          )
        },
        ...(version !== 'text' ? [
          {
            name: 'Where to',
            value: (
              <SmartTextField
                placeholder='Value'

                value={textToInnerHTML(item.value || '')}

                onChange={(valueNew: any) => form.onChange(prefix, textToInnerHTML(valueNew), `${property !== undefined ? `${property}.` : ''}value`)}

                additional={{
                  version: 'l1'
                }}
              />
            )
          }
        ] : [])
      ]}

      items_end={[
        ...(important ? [
          {
            name: 'Important',
            value: (
              <Switch
                value={item.priority === 'primary'}

                onChange={(valueNew: any) => form.onChange(prefix, valueNew ? 'primary' : 'regular', `${property !== undefined ? `${property}.` : ''}priority`)}

                size='regular'
              />
            )
          }
        ] : []),
        {
          value: (
            <Select
              name='Type'

              value={version || 'to'}

              onChange={(valueNew: any) => form.onChange(prefix, valueNew, `${property !== undefined ? `${property}.` : ''}version`)}

              options={[
                { name: 'Page', value: 'to' },
                { name: 'Link', value: 'link' },
                { name: 'Email', value: 'email' },
                { name: 'Phone', value: 'phone' },
                { name: 'Text', value: 'text' }
              ]}

              version='text'

              size='small'

              clear={false}

              WrapperProps={{
                style: {
                  width: 130,
                  minWidth: 'unset'
                }
              }}

              style={{
                width: 130,
                'min-width': 'unset !important'
              }}
            />
          )
        },
        {
          value: (onMove || onRemove) && (
            <Line
              gap={1}

              direction='row'

              align='center'
            >
              {onMove && <>
                <Tooltip
                  name='Move up'
                >
                  <IconButton
                    onClick={() => onMove(index, index - 1)}

                    disabled={!index}
                  >
                    <IconMaterialKeyboardArrowUp {...iconProps} />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  name='Move down'
                >
                  <IconButton
                    onClick={() => onMove(index, index + 1)}

                    disabled={index === menu.length - 1}
                  >
                    <IconMaterialKeyboardArrowDown {...iconProps} />
                  </IconButton>
                </Tooltip>
              </>}

              {onRemove && (
                <Tooltip
                  name='Remove'
                >
                  <IconButton
                    onClick={() => onRemove(index)}
                  >
                    <IconMaterialRemove {...iconProps} />
                  </IconButton>
                </Tooltip>
              )}
            </Line>
          )
        }
      ]}
    />
  );
});

export default Element;
