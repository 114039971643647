import React from 'react';
import HelmetElement from 'react-helmet';

import { cleanValue, textToInnerHTML } from '@onesy/utils';
import { IBaseElement } from '@onesy/ui-react/types';

export interface IMeta extends IBaseElement {
  app?: string;

  name?: string;

  logo?: string;
}

const Helmet: any = HelmetElement;

const Element: React.FC<IMeta> = props => {
  const {
    name: nameProps,

    app = 'personal-trainer',

    logo: logoProps,

    children
  } = props;

  const name = React.useMemo(() => {
    if (nameProps) return nameProps;

    if (app === 'onesy') return app;

    if (app === 'personal-trainer') return 'Personal Trainer';

    if (app === 'url-shortener') return 'URL shortener';

    return cleanValue(app, { capitalize: true });
  }, [nameProps, app]);

  const logo = React.useMemo(() => {
    return logoProps || `/assets/favicon/favicon.svg`;
  }, [logoProps]);

  return (
    <Helmet>
      <title>{textToInnerHTML(name) || 'Personal Trainer'}</title>

      <link rel='icon' type='image/svg' sizes='32x32' href={logo as any} />

      {children}
    </Helmet>
  );
};

export default Element;
