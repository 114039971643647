import React from 'react';

import { capitalize } from '@onesy/utils';
import { Line, Link, Type } from '@onesy/ui-react';

import { Input } from 'ui';
import { getDate } from 'utils';

const toName = (value_: any, toCapitalize = true) => {
  let valueNew = value_;

  if (value_ === 'urls') valueNew = 'links';

  if (value_ === 'custom') valueNew = 'more';

  return toCapitalize ? capitalize(valueNew) : valueNew;
};

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object,

    versions = ['tels', 'emails', 'titles', 'urls', 'addresses', 'companies', 'birthdays', 'dates', 'custom'],

    ...other
  } = props;

  const refs = {
    object: React.useRef(object)
  };

  refs.object.current = object;

  const getProperty = React.useCallback((key: string, itemProperty: any) => {
    const item = object.properties[key]?.find((item_: any) => item_.id === itemProperty?.id);

    if (!item) return null;

    const otherProps = {
      version: 'b1',
      children: item.value
    };

    let Element = <Type />;

    if (item.version === 'email') Element = <Link href={`mailto:${item.value}`} />;

    if (['mobile', 'tel', 'fax'].includes(item.version)) Element = <Link href={`tel:${item.value}`} />;

    if (item.version === 'url') Element = <Link href={item.value} />;

    if (['birthday', 'date'].includes(item.version)) otherProps.children = getDate(item.value);

    return (
      <Line
        key={item.id}

        direction='row'

        align='center'

        fullWidth
      >
        {React.cloneElement(Element, {
          ...otherProps
        })}
      </Line>
    );
  }, []);

  const properties_ = object.properties || {};

  return (
    <Line
      gap={1.5}

      fullWidth

      {...other}
    >
      {versions.map((version: string) => (
        <Input
          key={version}

          name={toName(version)}

          gap={0.5}

          size='small'

          wrapper
        >
          <Line
            gap={0.5}

            direction='column'

            fullWidth
          >
            {properties_[version]?.map((item: any) => getProperty(version, item))}
          </Line>
        </Input>
      ))}
    </Line>
  );
});

export default Element;
