import React from 'react';

import { is, textToInnerHTML } from '@onesy/utils';
import { Form, IconButton, Line, ModalFooter, ModalHeader, ModalMain, ModalTitle, Tab, Tabs, Tooltip, Type, useMediaQuery } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';
import { IForm } from '@onesy/ui-react/Form/Form';

import IconMaterialSubject from '@onesy/icons-material-rounded-react/IconMaterialSubjectW100';
import IconMaterialEdit from '@onesy/icons-material-rounded-react/IconMaterialEditW100';
import IconMaterialKeyboardArrowDown from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowDownW100';
import IconMaterialDelete from '@onesy/icons-material-rounded-react/IconMaterialDeleteW100';

import { Button } from 'ui';

const useStyle = style(theme => ({
  root: {

  },

  root_smaller: {
    maxHeight: '90vh'
  },

  header: {
    position: 'relative',
    padding: '12px 20px',
    minHeight: 40,

    '&.onesy-ModalHeader-root': {
      padding: 0
    }
  },

  wrapperName: {
    alignSelf: 'center'
  },

  name: {
    wordBreak: 'break-word',
    alignSelf: 'center'
  },

  main: {
    position: 'relative',
    minHeight: 84,
    maxHeight: 'calc(100vh - 315px)',
    overflow: 'hidden auto'
  },

  mainRegular: {
    padding: 32
  },

  mainFullScreen: {
    padding: 0
  },

  maxHeight: {
    height: 0,
    maxHeight: 'unset'
  },

  modalFooter: {
    overflow: 'auto hidden',
    zIndex: 1,

    '&.onesy-ModalFooter-root': {
      padding: '16px 20px'
    }
  },

  modalFooterMobile: {
    overflow: 'auto hidden',
    zIndex: 1,

    '&.onesy-ModalFooter-root': {
      padding: '10px 4px 10px 20px'
    }
  },

  other: {
    marginLeft: 'auto'
  },

  footer: {

  },

  ...theme.classes(theme)
}), { name: 'onesy-ModalForm' });

export interface IModalForm extends IForm {
  object?: any;

  name?: string;

  nameAdd?: any;

  nameUpdate?: any;

  nameRead?: any;

  tabDefault?: string;
  tab?: string;

  tabs?: string[];

  onNext?: (event?: any) => any;
  onClose?: () => any;

  loading?: boolean;

  onSubmit?: (event: SubmitEvent) => any;
  onChangeTab?: (value: string) => any;
  onChangeMode?: (value: string) => any;

  onRemove?: any;

  add?: boolean;
  close?: boolean;

  read?: any;
  write?: any;
  update?: any;
  more?: any;

  fullScreen?: boolean;
  noUpdate?: boolean;

  maxHeight?: boolean;

  startHeaderLeft?: any;
  endHeaderLeft?: any;

  startHeaderRight?: any;
  endHeaderRight?: any;

  footer?: boolean;

  footerLeftStart?: any;
  footerLeftEnd?: any;

  footerRightStart?: any;
  footerRightEnd?: any;

  footerLeftStartRead?: any;
  footerLeftEndRead?: any;

  footerRightStartRead?: any;
  footerRightEndRead?: any;

  WrapperProps?: any;
  MoreProps?: any;
  NextProps?: any;
  MainProps?: any;
  ModalMainProps?: any;
  TabsProps?: any;
  TabProps?: any;
  CloseProps?: any;
}

const ModalForm: React.FC<IModalForm> = React.forwardRef((props, ref: any) => {
  const {
    object,

    name: name_,

    nameAdd,

    nameUpdate,

    nameRead,

    tabDefault,

    tab: tab_,

    tabs,

    loading,

    add,

    footer: withFooter = true,
    close = false,

    fullScreen,

    onSubmit,
    onNext,
    onClose,

    onRemove,

    onChangeTab: onChangeTab_,
    onChangeMode,

    startHeaderLeft,
    endHeaderLeft,

    startHeaderRight,
    endHeaderRight,

    footerLeftStart,
    footerLeftEnd,

    footerRightStart,
    footerRightEnd,

    footerLeftStartRead,
    footerLeftEndRead,

    footerRightStartRead,
    footerRightEndRead,

    className,

    read,
    write,
    update,

    maxHeight,

    singular,

    noWrite,

    style: styleProps,

    children,

    NextProps,
    MainProps,
    ModalMainProps,
    TabsProps,
    TabProps,
    CloseProps
  } = props;

  const { classes } = useStyle();

  const mobile = useMediaQuery('(max-width: 1023px)');
  const mobileSmall = useMediaQuery('(max-width: 699px)');

  const [tab, setTab] = React.useState<string>(tabDefault);
  const [mode, setMode] = React.useState<any>(add ? 'update' : 'read');
  const [header, setHeader] = React.useState<HTMLElement>();
  const [footer, setFooter] = React.useState<HTMLElement>();

  const refs = {
    mode: React.useRef(mode),
    header: React.useRef<HTMLElement>(undefined),
    headerInitial: React.useRef<HTMLElement>(undefined),
    footer: React.useRef<HTMLElement>(undefined),
    footerInitial: React.useRef<HTMLElement>(undefined)
  };

  refs.mode.current = mode;

  refs.header.current = header;

  refs.footer.current = footer;

  const [maxHeightStyle, setMaxHeightStyle] = React.useState<string>(`calc(100vh - ${(((refs.header.current || refs.headerInitial.current)?.offsetHeight || 0) + ((refs.footer.current || refs.footerInitial.current)?.offsetHeight || 0) + (maxHeight ? 0 : 80))}px)`);

  React.useEffect(() => {
    if (tab !== tab_) setTab(tab_!);
  }, [tab_]);

  React.useEffect(() => {
    setMaxHeightStyle(`calc(100vh - ${(((refs.header.current || refs.headerInitial.current)?.offsetHeight || 0) + ((refs.footer.current || refs.footerInitial.current)?.offsetHeight || 0) + (maxHeight ? 0 : 80))}px)`);
  }, [header, refs.headerInitial.current, footer, refs.footerInitial.current, mode, (footerLeftStart || footerLeftEnd || footerRightStart || footerRightEnd || footerLeftStartRead || footerLeftEndRead || footerRightStartRead || footerRightEndRead)]);

  const onChangeTab = React.useCallback((value: any) => {
    if (!props.hasOwnProperty('tab')) setTab(value);

    if (is('function', onChangeTab_)) onChangeTab_!(value);
  }, [onChangeTab_]);

  const onModeToggle = React.useCallback(() => {
    const valueNew = refs.mode.current === 'read' ? 'update' : 'read';

    setMode(valueNew);

    if (is('function', onChangeMode)) onChangeMode!(valueNew);
  }, [onChangeMode]);

  const left = (footerLeftStartRead || footerLeftStart || (close && onClose) || onRemove || footerLeftEnd || footerLeftEndRead);

  const right = (mode === 'read' && footerRightStartRead) || footerRightStart || (mode === 'update' && onNext) || footerRightEnd || (mode === 'read' && footerRightEndRead);

  let name = name_ !== undefined ? name_ : nameRead !== undefined ? nameRead : object?.name;

  if (mode === 'update') name = name_ !== undefined ? name_ : !object ? nameAdd !== undefined ? nameAdd : `Add new${singular ? ` ${singular}` : ''}` : nameUpdate !== undefined ? nameUpdate : `Update${singular ? ` ${singular}` : ''}`;

  const buttonsHeaderProps: any = {
    size: 'large'
  };

  const buttonsFooterProps: any = {
    size: 'large'
  };

  const iconProps: any = {
    size: 'large'
  };

  return (
    <Form
      gap={0}

      onSubmit={onSubmit}

      flex

      className={classNames([
        className,
        classes.root
      ])}

      style={styleProps}
    >
      <ModalHeader
        gap={0}

        ref={item => {
          refs.headerInitial.current = item;

          setTimeout(() => {
            if (!refs.header.current) setHeader(item);
          }, 140);
        }}
      >
        <Line
          direction={{
            default: 'row',
            // 700: 'column'
          }}

          align={{
            default: 'flex-start',
            // 700: 'flex-end'
          }}

          justify={{
            default: 'space-between',
            // 700: 'flex-start'
          }}

          wrap='wrap'

          fullWidth

          className={classNames([
            classes.header
          ])}
        >
          <Line
            gap={1.25}

            direction='row'

            wrap='wrap'

            className={classNames([
              classes.wrapperName
            ])}
          >
            {startHeaderLeft}

            {!is('simple', name) ? name : (
              <ModalTitle
                version='h3'

                align='left'

                className={classes.name}

                dangerouslySetInnerHTML={{
                  __html: textToInnerHTML(name)
                }}
              />
            )}

            {endHeaderLeft}
          </Line>

          <Line
            gap={1}

            direction='row'

            align='center'

            className={classes.other}
          >
            {startHeaderRight}

            {!add && !noWrite && (
              <Tooltip
                name={mode === 'read' ? 'Update' : 'View'}
              >
                <IconButton
                  color='inherit'

                  onClick={onModeToggle}

                  {...buttonsHeaderProps}
                >
                  {mode === 'read' ? <IconMaterialEdit  {...iconProps} /> : <IconMaterialSubject {...iconProps} />}
                </IconButton>
              </Tooltip>
            )}

            {endHeaderRight}

            <Tooltip
              name='Close'
            >
              <IconButton
                color='inherit'

                onClick={onClose}

                {...buttonsHeaderProps}

                className={classNames([
                  buttonsHeaderProps?.className
                ])}
              >
                <IconMaterialKeyboardArrowDown {...iconProps} />
              </IconButton>
            </Tooltip>
          </Line>
        </Line>

        {tabs?.length && (
          <Tabs
            value={tab}

            onChange={onChangeTab}

            justify='flex-start'

            size='small'

            {...TabsProps}
          >
            {tabs.map((item: any) => {
              const itemValue = item.value || item.name || item;

              return (
                <Tab
                  key={itemValue}

                  value={itemValue}

                  icon={item.Icon ? <item.Icon {...iconProps} /> : null}

                  {...item.props}

                  {...TabProps}
                >
                  <Type
                    version='t2'

                    whiteSpace='nowrap'
                  >
                    {itemValue}
                  </Type>
                </Tab>
              );
            })}
          </Tabs>
        )}
      </ModalHeader>

      <ModalMain
        align='center'

        justify='unset'

        fullWidth

        {...ModalMainProps}
      >
        <Line
          align='center'

          justify='unset'

          flex

          fullWidth

          {...MainProps}

          className={classNames([
            MainProps?.className,
            classes.main,
            fullScreen ? classes.fullScreen : classes.mainRegular,
            !mobile && maxHeight && classes.maxHeight
          ])}

          style={{
            ...(mobile ? {
              maxHeight: 'unset',
              height: 0
            } : {
              maxHeight: maxHeightStyle
            }),

            ...MainProps?.style
          }}
        >
          {children !== undefined ? children : <>
            {(read || write || update) && (mode === 'read' ? read : write || update)}
          </>}
        </Line>
      </ModalMain>

      {(withFooter && (left || right)) && (
        <ModalFooter
          ref={item => {
            refs.footerInitial.current = item;

            setTimeout(() => {
              if (!refs.footer.current) setFooter(item);
            }, 140);
          }}

          direction='row'

          align='center'

          justify='center'

          className={classNames([
            mobileSmall ? classes.modalFooterMobile : classes.modalFooter
          ])}
        >
          <Line
            direction={{
              default: 'row',
              700: 'column'
            }}

            align='center'

            justify={left ? 'space-between' : 'flex-end'}

            fullWidth

            className={classNames([
              classes.footer
            ])}
          >
            {left && (
              <Line
                gap={2}

                direction='row'

                align='center'

                flexNo

                className={classNames([
                  mobileSmall && classes.actions
                ])}

                style={{
                  ...(mobileSmall && { alignSelf: 'flex-start' })
                }}
              >
                {mode === 'read' && footerLeftStartRead}

                {footerLeftStart && (
                  <Line
                    gap={1}

                    direction='row'

                    align='center'
                  >
                    {footerLeftStart}
                  </Line>
                )}

                {close && onClose && (
                  <Button
                    version='text'

                    color='inherit'

                    onClick={onClose}

                    disabled={loading}

                    {...buttonsFooterProps}

                    {...CloseProps}
                  >
                    Close
                  </Button>
                )}

                {onRemove && (
                  <Tooltip
                    name='Remove'
                  >
                    <IconButton
                      size='small'

                      onClick={onRemove}
                    >
                      <IconMaterialDelete {...iconProps} />
                    </IconButton>
                  </Tooltip>
                )}

                {footerLeftEnd && (
                  <Line
                    gap={1}

                    direction='row'

                    align='center'
                  >
                    {footerLeftEnd}
                  </Line>
                )}

                {mode === 'read' && footerLeftEndRead}
              </Line>
            )}

            {right && (
              <Line
                gap={2}

                direction='row'

                align='center'

                flexNo

                className={classNames([
                  mobileSmall && classes.actions
                ])}

                style={{
                  ...(mobileSmall && { alignSelf: 'flex-end' })
                }}
              >
                {mode === 'read' && footerRightStartRead}

                {footerRightStart && (
                  <Line
                    gap={1}

                    direction='row'

                    align='center'
                  >
                    {footerRightStart}
                  </Line>
                )}

                {mode === 'update' && onNext && (
                  <Button
                    version='filled'

                    color='primary'

                    onClick={onNext}

                    {...buttonsFooterProps}

                    {...NextProps}

                    disabled={loading || NextProps?.disabled || NextProps?.loading}
                  >
                    {(add && !object) ? 'Add' : 'Update'}
                  </Button>
                )}

                {footerRightEnd && (
                  <Line
                    gap={1}

                    direction='row'

                    align='center'
                  >
                    {footerRightEnd}
                  </Line>
                )}

                {mode === 'read' && footerRightEndRead}
              </Line>
            )}
          </Line>
        </ModalFooter>
      )}
    </Form>
  );
});

export default ModalForm;
