import { textToInnerHTML } from '@onesy/utils';
import { Meeting, User } from '@onesy/api';
import { OnesyDate } from '@onesy/date';

import { BaseService } from './base';
import { MediaService } from 'services';
import { audioFix, mediaToObject } from 'utils';

class MeetingService extends BaseService<Meeting> {
  public mediaStreams: MediaStream[] = [];
  public recorder: MediaRecorder;
  public recorderChunks = [];
  public audioContext: AudioContext;
  public destination: MediaStreamAudioDestinationNode;
  public merger: ChannelMergerNode;
  public sourceNodes = [];
  public isRecording = false;

  get paginationLimit() {
    return 50;
  }

  public constructor() {
    super('meetings');
  }

  public async updateRecording() {
    if (!this.isRecording) return;

    // Disconnect all existing sources from the merger
    this.sourceNodes.forEach((node) => node.disconnect());
    this.sourceNodes.length = 0;

    // Update merger channels and reconnect sources
    this.merger.disconnect();

    const newMerger = this.audioContext.createChannelMerger(this.mediaStreams.length);

    this.mediaStreams.forEach((stream, index) => {
      const source = this.audioContext.createMediaStreamSource(stream);

      source.connect(newMerger, 0, index);

      this.sourceNodes.push(source);
    });

    newMerger.connect(this.destination);

    return newMerger;
  }

  public async startRecording() {
    this.isRecording = true;

    this.audioContext = new AudioContext();
    this.destination = this.audioContext.createMediaStreamDestination();
    this.merger = this.audioContext.createChannelMerger(this.mediaStreams.length);

    this.updateRecording();

    this.recorder = new MediaRecorder(this.destination.stream);

    this.recorderChunks = [];

    this.recorder.ondataavailable = event => {
      if (event.data.size > 0) this.recorderChunks.push(event.data);
    };

    // Start recording
    this.recorder.start(1e3);

    return;
  }

  public cleanUpRecording() {
    this.mediaStreams = [];
    this.recorder = null;
    this.recorderChunks = [];
    this.audioContext = null;
    this.destination = null;
    this.merger = null;
    this.isRecording = false;
  }

  public async endRecording(meeting: Meeting, user: User) {
    if (!this.recorder) return;

    const recorderChunks = [...this.recorderChunks];

    if (this.recorder) this.recorder.stop();

    const name = `${textToInnerHTML(meeting.name || 'Meeting')} meeting, recording by ${textToInnerHTML(user.name)}`;

    const type = 'audio/webm';

    const blobOriginal = new Blob(recorderChunks, { type });

    const {
      blob,
      duration
    } = await audioFix(blobOriginal);

    const file = new File([blob], name, { type });

    // meta
    const meta: any = {};

    if (duration) meta.duration = duration;

    // add media 
    const resultMedia = await MediaService.add({
      name,

      id_internal: `meeting-${meeting.id}`,

      query: false,

      meta,

      app: 'meeting',

      // media
      media: file
    }, { query: { browser: true } });

    if (resultMedia.status >= 400) {
      this.cleanUpRecording();

      return;
    }

    const objectMedia = resultMedia.response.response;

    const media = mediaToObject({
      id: objectMedia?.id,
      name: objectMedia.name || 'Meeting audio recording',
      mime: objectMedia.mime,
      duration: objectMedia.duration || objectMedia?.meta?.duration,
      meta: objectMedia?.meta,
      versions: objectMedia?.versions,
      added_at: OnesyDate.milliseconds
    });

    this.cleanUpRecording();

    return media;
  }

}

export default new MeetingService();
