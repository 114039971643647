import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Checkbox, Divider, Form, FormRow, Label, Line, Link, Type, useForm, useQuery, useSnackbars } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';
import { ISignUp, ISignUpGoogle } from '@onesy/sdk/other';
import { ValidationError } from '@onesy/errors';

import { ReactComponent as IconGoogleSymbol } from 'assets/svg/google-logo-symbol.svg';

import { Button, TextField } from 'ui';
import { AuthService, UserService } from 'services';
import { getDeviceAndLocation, getErrorMessage, googleLibrary } from 'utils';
import config from 'config';

const useStyle = style(theme => ({
  root: {},

  buttons: {
    marginTop: 30
  },

  buttonGoogle: {
    backgroundColor: theme.palette.light ? theme.palette.color.primary[100] : theme.palette.color.primary[10],
    transition: `${[theme.methods.transitions.make(['color', 'box-shadow']), theme.methods.transitions.make('transform', { duration: 'rg' })].join(', ')} !important`,

    '&:active': {
      transform: 'scale(0.97)'
    }
  },

  iconGoogle: {
    width: 'auto',
    height: 18,
    marginRight: 4,
    flex: '0 0 auto'
  }
}), { name: 'onesy-SignUp' });

const SignUp = React.forwardRef((props: any, ref: any) => {
  const {
    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const query = useQuery();
  const navigate = useNavigate();
  const snackbars = useSnackbars();

  const [step, setStep] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const formSignUp = useForm({
    values: {
      'user.name': {
        name: 'name',
        is: 'string',
        min: 2,
        max: 1400,
        messages: {
          min: 'Name must be min 2 characters',
          max: 'Name can be max 1400 characters'
        },
        required: true
      },
      'user.email': {
        name: 'email',
        isValid: 'email',
        value: UserService.invite?.email,
        required: true
      },
      'user.settings.security.password': {
        name: 'password',
        isValid: 'password',
        messages: {
          isValid: 'Password must have min 7 characters, with at least one lowercase, uppercase and a number character'
        },
        required: true
      },
      'accepted_legal': {
        name: 'Accepting Terms of Use & Privacy Policy',
        is: 'boolean',
        method: (item: any) => {
          if (!item) throw new ValidationError(`Accepting legal terms is required`);
        },
        required: true
      }
    }
  });

  const formOTP = useForm({
    values: {
      'otp': {
        name: 'OTP',
        required: true
      }
    }
  });

  const refs = {
    onesy: React.useRef<any>(undefined),
    formSignUp: React.useRef(formSignUp),
    formOTP: React.useRef(formOTP)
  };

  refs.formSignUp.current = formSignUp;

  refs.formOTP.current = formOTP;

  const signInUser = React.useCallback(async (value: string) => {
    // google modal close
    (window as any).google.accounts.id.cancel();

    await AuthService.init(value);

    // to home
    navigate('/');
  }, []);

  const onSignUpGoogle = React.useCallback(async (...args: any[]) => {
    const googleResponse = args[0];

    const token = googleResponse.access_token || googleResponse.credential;

    const valid = !!token;

    if (!valid) return;

    setLoading(true);

    const body: Partial<ISignUpGoogle> = {
      ...(await getDeviceAndLocation()),

      google: {
        access_token: googleResponse.access_token,
        credential: googleResponse.credential
      },

      accepted_legal: true
    };

    const invite = UserService.invite || query.get('invite');

    if (invite) body.invite = { id: invite?.id || invite };

    const add_website = query.get('add_website');

    const result = await AuthService.signUpGoogle(
      body as ISignUpGoogle,
      {
        query: {
          ...(add_website && { add_website })
        }
      }
    );

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      snackbars.add({
        primary: `Signed up successfully`
      });

      await signInUser(result.response.response);
    }

    setLoading(false);
  }, [query]);

  const getGoogleAccessToken = () => {
    return new Promise((resolve, reject) => {
      if (googleLibrary.oauth2) {
        googleLibrary.oauth2.callback = (response: any) => resolve(response);

        googleLibrary.oauth2.error_callback = (response: any) => resolve(response);

        googleLibrary.oauth2.requestAccessToken();
      }
    });
  };

  const onGoogleClick = React.useCallback(async () => {
    const response = await getGoogleAccessToken();

    onSignUpGoogle(response);
  }, []);

  const googlePrompt = React.useCallback(() => {
    if ((window as any).google) {
      googleLibrary.callback = onSignUpGoogle;

      (window as any).google.accounts?.id?.prompt();
    }
  }, []);

  const init = React.useCallback(() => {
    // Google sign in propmpt on init
    googlePrompt();
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, []);

  const onSignUp = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    const valid = await formSignUp.validate();

    if (!valid) return;

    setLoading(true);

    const body: Partial<ISignUp> = {
      ...(await getDeviceAndLocation()),

      ...refs.formSignUp.current.value
    };

    const invite = UserService.invite || query.get('invite');

    if (invite) body.invite = { id: invite?.id || invite };

    const add_website = query.get('add_website');

    const result = await AuthService.signUp(
      body as ISignUp,
      {
        query: {
          ...(add_website && { add_website })
        }
      }
    );

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      if (result.response.meta?.sign_in) {
        await signInUser(result.response.response);
      }
      else {
        // otp
        setStep(2);
      }

      snackbars.add({
        primary: `Sign up successful`
      });
    }

    setLoading(false);
  }, [query, formSignUp]);

  const onSignUpConfirm = React.useCallback(async (event: SubmitEvent) => {
    event.preventDefault();

    let valid = await formSignUp.validate();

    if (!valid) return;

    valid = await formOTP.validate();

    if (!valid) return;

    setLoading(true);

    const add_website = query.get('add_website');

    const result = await AuthService.signUpOTP(
      {
        ...(await getDeviceAndLocation()),

        email: refs.formSignUp.current.value.user.email,
        otp: refs.formOTP.current.value.otp
      },
      {
        query: {
          ...(add_website && { add_website })
        }
      }
    );

    if (result.status >= 400) {
      snackbars.add({
        color: 'error',
        primary: getErrorMessage(result)
      });
    }
    else {
      // google modal close
      (window as any).google.accounts.id.cancel();

      const response = result.response.response;

      await signInUser(response);

      snackbars.add({
        primary: 'Sign up confirmed succefully!'
      });
    }

    setLoading(false);
  }, [query, formSignUp, formOTP]);

  const onLinkClick = React.useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const steps: any = {
    1: <>
      <Type
        version='h3'
      >
        Sign up
      </Type>

      <Line
        gap={3}

        fullWidth
      >
        <Line
          gap={1.5}

          fullWidth
        >
          <Button
            color='themed'

            fullWidth

            onClick={onGoogleClick}

            className={classes.buttonGoogle}

            disabled={loading}
          >
            <IconGoogleSymbol
              className={classes.iconGoogle}
            />

            <Type
              version='t3'
            >
              Sign up with Google
            </Type>
          </Button>

          <Type
            version='b3'

            align='center'
          >
            By clicking the Sign up with Google button, you confirm you have read and accepted the <Link version='b3' href={`${config.value.apps.website.url}/terms-of-use`}>Terms of Use</Link> & <Link version='b3' href={`${config.value.apps.website.url}/privacy-policy`}>Privacy Policy</Link>.
          </Type>
        </Line>

        <Divider
          opacity={1}
        >
          or
        </Divider>

        <Form
          onSubmit={onSignUp}
        >
          <FormRow
            fullWidth
          >
            <TextField
              name='Name'

              valueDefault={formSignUp.values['user.name'].value}

              error={formSignUp.values['user.name'].error}

              helperText={formSignUp.values['user.name'].error}

              onChange={(valueNew: any) => formSignUp.onChange('user.name', valueNew, undefined, { rerenderOnUpdate: false })}

              fullWidth
            />

            <TextField
              name='Email'

              type='email'

              valueDefault={formSignUp.values['user.email'].value}

              error={formSignUp.values['user.email'].error}

              helperText={formSignUp.values['user.email'].error}

              onChange={(valueNew: any) => formSignUp.onChange('user.email', valueNew, undefined, { rerenderOnUpdate: false })}

              fullWidth

              disabled={!!UserService.invite}
            />

            <TextField
              name='Password'

              type='password'

              valueDefault={formSignUp.values['user.settings.security.password'].value}

              error={formSignUp.values['user.settings.security.password'].error}

              helperText={formSignUp.values['user.settings.security.password'].error}

              onChange={(valueNew: any) => formSignUp.onChange('user.settings.security.password', valueNew, undefined, { rerenderOnUpdate: false })}

              footer={(
                <Line
                  direction='row'

                  justify='flex-end'

                  style={{
                    marginTop: 10
                  }}
                >
                  <Link
                    tonal

                    version='b2'

                    tabIndex={0}

                    onClick={() => navigate('/sign-up/confirm')}
                  >
                    Confirm signed up email
                  </Link>
                </Line>
              )}

              fullWidth
            />

            <Label
              position='start'

              checked={formSignUp.values.accepted_legal.value}

              onChange={(valueNew: any) => formSignUp.onChange('accepted_legal', valueNew)}

              error={!!formSignUp.values['accepted_legal'].error}

              helperText={formSignUp.values['accepted_legal'].error}

              style={{
                alignSelf: 'flex-start'
              }}
            >
              <Checkbox />

              <Type
                version='b2'
              >
                I've read and accepted <Link version='b2' onClick={onLinkClick} href={`${config.value.apps.website.url}/terms-of-use`}>Terms of Use</Link> & <Link version='b2' onClick={onLinkClick} href={`${config.value.apps.website.url}/privacy-policy`}>Privacy Policy</Link>.
              </Type>
            </Label>
          </FormRow>

          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classes.buttons}
          >
            <Button
              version='text'

              onClick={() => navigate('/sign-in')}
            >
              Sign in
            </Button>

            <Button
              type='submit'

              disabled={loading}
            >
              Next
            </Button>
          </Line>
        </Form>
      </Line>
    </>,

    2: <>
      <Line
        gap={3}

        direction='column'

        align='center'
      >
        <Type
          version='h3'
        >
          Sign up
        </Type>

        <Line
          gap={1}

          direction='column'

          align='center'
        >
          <Type
            version='t1'
          >
            One time password
          </Type>

          <Type
            version='b2'

            style={{
              textAlign: 'center'
            }}
          >
            We sent you a one time password to your email. Please enter it below, to confirm your sign up & activate your account.
          </Type>
        </Line>
      </Line>

      <Line
        gap={3}

        fullWidth
      >
        <Form
          onSubmit={onSignUpConfirm}
        >
          <TextField
            name='One time password'

            valueDefault={formOTP.values['otp'].value}

            error={formOTP.values['otp'].error}

            helperText={formOTP.values['otp'].error}

            onChange={(valueNew: any) => formOTP.onChange('otp', valueNew, undefined, { rerenderOnUpdate: false })}

            fullWidth
          />

          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classes.buttons}
          >
            <Button
              version='text'

              onClick={() => setStep(1)}
            >
              Back
            </Button>

            <Button
              type='submit'

              disabled={loading}
            >
              Next
            </Button>
          </Line>
        </Form>
      </Line>
    </>
  };

  return (
    <Line
      ref={ref}

      gap={4}

      direction='column'

      align='center'

      fullWidth

      className={classNames([
        className,
        classes.root,
        'onesy-SignUp'
      ])}

      {...other}
    >
      {steps[step]}
    </Line>
  );
});

export default SignUp;
