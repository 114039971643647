import React from 'react';
import Peer, { DataConnection, MediaConnection } from 'peerjs';

import { is, unique, wait } from '@onesy/utils';
import { useSnackbars, useSubscription } from '@onesy/ui-react';
import { User } from '@onesy/api';

import { AuthService, LogService, SocketService } from 'services';
import { ISocketUserConnection } from 'services/socket';
import { ISignedIn } from 'types';
import config from 'config';
import { isScreenShare } from 'utils';

export type IUseRoomDataType = 'board' | 'stream-mode' | 'stream-video' | 'stream-screen-share';

export type IUseRoomCall = Record<string, MediaConnection>;

export type IUseRoomConnection = Record<string, DataConnection>;

export type IUseRoomStreams = Record<string, MediaStream>;

export interface IUseRoomReturn {
  name: string;
  loaded: boolean;
  users: User[];
  calls: IUseRoomCall;
  connections: IUseRoomConnection;
  peer: Peer;
  userPeerID: string;
  isMainPeer: boolean;
  localStream?: MediaStream;
  remoteStreams?: IUseRoomStreams;
};

export interface IUseRoom {
  name: string;

  audio?: boolean;

  video?: boolean;

  screenShare?: boolean;

  loaded: boolean;

  onChangeData?: (data: any, peerID: string, type: IUseRoomDataType) => any;

  onScreenShareEnd?: () => any;

  useCall?: boolean;
}

const useRoom = (props: IUseRoom): IUseRoomReturn => {
  const {
    name,

    onChangeData,

    onScreenShareEnd,

    audio: audioProps,

    video: videoProps,

    screenShare,

    loaded,

    useCall
  } = props;

  const snackbars = useSnackbars();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);
  const userConection = useSubscription<ISocketUserConnection>(SocketService.userConnection);

  const [users, setUsers] = React.useState<User[]>([]);
  const [calls, setCalls] = React.useState<IUseRoomCall>({});
  const [connections, setConnections] = React.useState<IUseRoomConnection>({});
  const [localStream, setLocalStream] = React.useState<MediaStream>();
  const [remoteStreams, setRemoteStreams] = React.useState<IUseRoomStreams>({});
  const [isMainPeer, setIsMainPeer] = React.useState(false);
  const [inited, setInited] = React.useState(false);

  const refs = {
    peer: React.useRef<Peer>(null),
    name: React.useRef(name),
    user: React.useRef(signedIn?.user),
    userPeerID: React.useRef(`${name}-${signedIn.user.id}`),
    users: React.useRef(users),
    calls: React.useRef(calls),
    connections: React.useRef(connections),
    localStream: React.useRef(localStream),
    remoteStreams: React.useRef(remoteStreams),
    onChangeData: React.useRef(onChangeData),
    onScreenShareEnd: React.useRef(onScreenShareEnd),
    useCall: React.useRef(useCall),
    audio: React.useRef(audioProps),
    video: React.useRef(videoProps),
    loaded: React.useRef(loaded),
    inProgressLocalStream: React.useRef(false),
    inProgressReconnecting: React.useRef(false)
  };

  refs.name.current = name;

  refs.user.current = signedIn?.user;

  refs.users.current = users;

  refs.calls.current = calls;

  refs.connections.current = connections;

  refs.localStream.current = localStream;

  refs.remoteStreams.current = remoteStreams;

  refs.onChangeData.current = onChangeData;

  refs.onScreenShareEnd.current = onScreenShareEnd;

  refs.useCall.current = useCall;

  refs.audio.current = audioProps;

  refs.video.current = videoProps;

  refs.loaded.current = loaded;

  React.useEffect(() => {
    // clean up 
    return () => {
      // calls 
      Object.values(refs.calls.current).forEach(call => call?.close());

      // connections 
      Object.values(refs.connections.current).forEach(connection => connection?.close());

      // stream 
      if (refs.localStream.current) {
        refs.localStream.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const onUpdateSendersWithNewMode = React.useCallback((settings?: any) => {
    // update remote streams 
    Object.values(refs.calls.current).forEach(call => {
      // update sender to rerender 
      refs.connections.current[call.peer]?.send({ type: 'stream-mode', peerID: refs.userPeerID.current, settings });
    });
  }, []);

  // audio, video on / off 
  React.useEffect(() => {
    if (refs.localStream.current) {
      const videoTrack = refs.localStream.current.getVideoTracks()[0];

      if (videoTrack) videoTrack.enabled = !!videoProps;

      const audioTrack = refs.localStream.current.getAudioTracks()[0];

      if (audioTrack) audioTrack.enabled = !!audioProps;
    }

    onUpdateSendersWithNewMode({ audio: audioProps, video: videoProps });
  }, [audioProps, videoProps]);

  const onUpdateSendersWithNewTrack = React.useCallback((stream: MediaStreamTrack, screenShare = false) => {
    // update remote streams 
    Object.values(refs.calls.current).forEach(call => {
      const sender = call.peerConnection.getSenders().find(sender => sender.track.kind === 'video');

      sender.replaceTrack(stream);

      // update sender to rerender 
      refs.connections.current[call.peer]?.send({ type: screenShare ? 'stream-screen-share' : 'stream-video', peerID: refs.userPeerID.current });
    });
  }, []);

  const onScreenShare = React.useCallback(async (screenShareOn = screenShare) => {
    if (!refs.localStream.current) return;

    if (screenShareOn) {
      try {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: true
        });

        const newTrack = screenStream.getVideoTracks()[0];

        (newTrack as any).version = 'screen-share';

        setLocalStream(screenStream);

        // if it ends from user side 
        newTrack.onended = () => {
          onScreenShare(false);

          if (is('function', refs.onScreenShareEnd.current)) refs.onScreenShareEnd.current();
        };

        // update remote streams 
        onUpdateSendersWithNewTrack(newTrack, true);
      }
      catch (error) {
        if (is('function', refs.onScreenShareEnd.current)) refs.onScreenShareEnd.current();

        LogService.error('Screen share', error);
      }
    }
    else if (isScreenShare(refs.localStream.current)) {
      const videoTrack = refs.localStream.current.getVideoTracks()[0];

      videoTrack.stop();

      const newStream = await navigator.mediaDevices.getUserMedia({
        video: refs.video.current,
        audio: refs.audio.current
      });

      const newTrack = newStream.getVideoTracks()[0];

      setLocalStream(newStream);

      // update remote streams 
      onUpdateSendersWithNewTrack(newTrack);
    }
  }, []);

  // screen share 
  React.useEffect(() => {
    onScreenShare(screenShare);
  }, [screenShare]);

  const getLocalStream = React.useCallback(async (video = videoProps, audio = audioProps) => {
    if (refs.localStream.current || refs.inProgressLocalStream.current || !refs.useCall.current) return refs.localStream.current;

    refs.inProgressLocalStream.current = true;

    try {
      refs.localStream.current = await navigator.mediaDevices.getUserMedia({ video, audio });
    }
    catch (error) {
      snackbars.add({
        primary: error.message || error,
        color: 'error'
      });
    }

    const valueNew = refs.localStream.current || null;

    setLocalStream(valueNew);

    refs.inProgressLocalStream.current = false;

    return valueNew;
  }, []);

  const onAddStream = React.useCallback((call: MediaConnection, remoteStream: MediaStream) => {
    const valueNew = { ...refs.remoteStreams.current };

    valueNew[call.peer] = remoteStream;

    setRemoteStreams(valueNew);
  }, []);

  const onRemoveStream = React.useCallback((call: MediaConnection) => {
    const valueNew = { ...refs.remoteStreams.current };

    delete valueNew[call.peer];

    setRemoteStreams(valueNew);
  }, []);

  // initiate 
  const init = React.useCallback(async () => {
    if (!name) return;

    // local stream 
    await getLocalStream();

    // init sockets 
    const roomID = refs.name.current;

    const result = await SocketService.enterMeeting(roomID, refs.user.current.id);

    const roomData = result.response.response?.rooms?.[roomID];

    if (roomData) setUsers(roomData?.users || []);

    // init peer 
    const userPeerID = refs.userPeerID.current;

    const url = new URL(`${config.value.apps.api.url}/peer`);

    refs.peer.current = new Peer(userPeerID, {
      host: url.hostname,

      ...(url.port && { port: +url.port }),

      path: url.pathname,
      secure: url.protocol === 'https:',
      config: {
        iceServers: [
          // free Google STUN server 
          { url: 'stun:stun.l.google.com:19302' }
        ]
      }
    });

    const peer = refs.peer.current;

    // open 
    peer.on('open', () => {
      LogService.info('peer open', userPeerID);
    });

    // received a connection 
    peer.on('connection', connection => {
      const peerID = connection.peer;

      setConnections(previous => ({
        ...previous,

        [peerID]: connection
      }));

      LogService.debug('peer', 'received connection', userPeerID, peerID);
    });

    // received a call 
    if (refs.useCall.current) {
      peer.on('call', async call => {
        const peerID = call.peer;

        const localStreamValue = await getLocalStream();

        call.answer(localStreamValue);

        call.on('stream', remoteStream => {
          onAddStream(call, remoteStream);

          LogService.debug('peer on call stream', remoteStream);
        });

        call.on('close', () => {
          onRemoveStream(call);
        });

        call.on('error', () => {
          onRemoveStream(call);
        });

        // call.peerConnection.ontrack = event => {
        //   console.log(11);

        //   setRerender(OnesyDate.milliseconds);
        // };

        // call.peerConnection.onconnectionstatechange = event => {
        //   console.log(14);

        //   setRerender(OnesyDate.milliseconds);
        // };

        setCalls(previous => ({
          ...previous,

          [peerID]: call
        }));

        LogService.debug('peer', 'received a call', userPeerID, peerID);
      });
    }

    setInited(true);
  }, [name]);

  // init 
  React.useEffect(() => {
    if (!loaded) return;

    // init 
    init();

    return () => {
      const peer = refs.peer.current;

      peer?.disconnect();
    };
  }, [name, useCall, loaded]);

  // users update 
  React.useEffect(() => {
    if (!loaded) return;

    if (userConection?.room === refs.name.current) setUsers(userConection?.users);
  }, [userConection, loaded]);

  const onCloseAllConnections = React.useCallback(() => {
    const peerIDs = Object.keys(refs.connections.current);

    peerIDs.forEach(peerID => {
      const connection = refs.connections.current[peerID];

      if (connection?.open) connection.close();

      delete refs.connections.current[peerID];
    });
  }, []);

  // reconnect potentially, periodically every 1s 
  React.useEffect(() => {
    if (!loaded) return;

    const interval = setInterval(async () => {
      if (refs.inProgressReconnecting.current) return;

      refs.inProgressReconnecting.current = true;

      try {
        // connections 
        {
          const connections = Object.keys(refs.connections.current);

          const usersPeerIDs = refs.users.current.map(item => item.id_peer);

          // user is in the room 
          // but is disconnected only 
          const reconnectPeerIDs = connections.filter(peerID => !refs.connections.current[peerID]?.open && usersPeerIDs.includes(peerID));

          // console.log('reconnect', reconnectPeerIDs, usersPeerIDs, refs);

          if (reconnectPeerIDs.length) {
            const connectionsNew = { ...refs.connections.current };

            for (const peerID of reconnectPeerIDs) {
              console.log('Reconnecting to', peerID, connectionsNew[peerID]);

              connectionsNew[peerID]?.close();

              await wait(440);

              connectionsNew[peerID] = refs.peer.current.connect(peerID);
            }

            setConnections(connectionsNew);
          }
        }

        // calls 
        if (refs.useCall.current) {
          const calls = Object.keys(refs.calls.current);

          const usersPeerIDs = refs.users.current.map(item => item.id_peer);

          // user is in the room 
          // but is disconnected only 
          const reconnectPeerIDs = calls.filter(peerID => !refs.calls.current[peerID]?.open && usersPeerIDs.includes(peerID));

          // console.log('recalling', reconnectPeerIDs, usersPeerIDs, refs);

          if (reconnectPeerIDs.length) {
            const callsNew = { ...refs.calls.current };

            const localStreamValue = await getLocalStream();

            for (const peerID of reconnectPeerIDs) {
              console.log('Recalling', peerID, callsNew[peerID]);

              callsNew[peerID]?.close();

              await wait(440);

              callsNew[peerID] = refs.peer.current.call(peerID, localStreamValue);
            }

            setCalls(callsNew);
          }
        }
      }
      catch (error) {
        console.log('Reconnect try error', error);
      }

      refs.inProgressReconnecting.current = false;
    }, 1e3);

    return () => {
      // clean up 
      onCloseAllConnections();

      clearInterval(interval);
    };
  }, [loaded]);

  // recreate local stream, periodically every 1s 
  React.useEffect(() => {
    if (!inited || !refs.useCall.current) return;

    const interval = setInterval(async () => {
      if (!refs.localStream.current?.active) {
        const newLocalStream = await getLocalStream();

        const newTrack = newLocalStream.getVideoTracks()[0];

        onUpdateSendersWithNewTrack(newTrack);
      }
    }, 1e3);

    return () => {
      clearInterval(interval);
    };
  }, [inited]);

  // update connections 
  // if users updated 
  const updateConnections = React.useCallback(async () => {
    if (!refs.peer.current) return;

    await wait(140);

    // connections 
    {
      const userPeerIDs = refs.users.current?.map(item => item.id_peer).filter(peerID => !refs.connections.current[peerID]?.open);

      const usersDisconnectedIDs = Object.keys(refs.connections.current).filter(peerID => !refs.connections.current[peerID]?.open);

      // other than user self 
      const userPeerIDsToConnect = unique([...userPeerIDs, ...usersDisconnectedIDs]).filter(peerID => peerID !== refs.userPeerID.current);

      setConnections(previous => {
        const valueNew = { ...previous };

        userPeerIDsToConnect.forEach(peerID => valueNew[peerID] = refs.peer.current.connect(peerID));

        // clean up 
        // if user no longer exists 
        // remove the connection  
        const peersToDisconnect = Object.keys(valueNew).filter(peerID => !userPeerIDs.includes(peerID));

        peersToDisconnect.forEach(peerID => {
          if (valueNew[peerID]?.open) {
            valueNew[peerID].close();

            LogService.warn('peer disconnect, connection close', peerID);
          }

          delete valueNew[peerID];
        });

        return valueNew;
      });
    }

    // calls 
    {
      const userPeerIDs = refs.users.current?.map(item => item.id_peer).filter(peerID => !refs.calls.current[peerID]?.open);

      const usersDisconnectedIDs = Object.keys(refs.calls.current).filter(peerID => !refs.calls.current[peerID]?.open);

      // other than user self 
      const userPeerIDsToConnect = unique([...userPeerIDs, ...usersDisconnectedIDs]).filter(peerID => peerID !== refs.userPeerID.current);

      const localStreamValue = await getLocalStream();

      setCalls(previous => {
        const valueNew = { ...previous };

        userPeerIDsToConnect.forEach(peerID => valueNew[peerID] = refs.peer.current.call(peerID, localStreamValue));

        // clean up 
        // if user no longer exists 
        // remove the call  
        const peersToDisconnect = Object.keys(valueNew).filter(peerID => !userPeerIDs.includes(peerID));

        peersToDisconnect.forEach(peerID => {
          if (valueNew[peerID]?.open) {
            valueNew[peerID].close();

            LogService.warn('peer disconnect, call closed', peerID);
          }

          delete valueNew[peerID];
        });

        return valueNew;
      });
    }
  }, []);

  React.useEffect(() => {
    if (!loaded) return;

    // update 
    // connections 
    updateConnections();

    // isMainPeer 
    setIsMainPeer(users.length === 1 || refs.userPeerID.current === users[0]?.id_peer);
  }, [users, loaded]);

  // connection listeners 
  // open, data events 
  React.useEffect(() => {
    if (!loaded) return;

    const peerIDs = Object.keys(connections);

    peerIDs.forEach(peerID => {
      const connection = connections[peerID];

      if (!connection) return;

      connection.on('open', () => {
        LogService.info('peer', 'connection open', peerID);

        connection.on('data', (data: any) => {
          if (is('function', refs.onChangeData.current)) refs.onChangeData.current(data, peerID, data.type || 'board');
        });
      });
    });
  }, [connections, loaded]);

  // call listeners 
  // stream events 
  React.useEffect(() => {
    if (!loaded || !refs.useCall.current) return;

    const peerIDs = Object.keys(calls);

    peerIDs.forEach(peerID => {
      const call = calls[peerID];

      if (!call) return;

      call.on('stream', remoteStream => {
        onAddStream(call, remoteStream);

        LogService.debug('peer listener call on stream', peerID);
      });
    });
  }, [calls, loaded]);

  return {
    name: refs.name.current,
    loaded,
    users,
    calls,
    connections,
    peer: refs.peer.current,
    userPeerID: refs.userPeerID.current,
    localStream,
    remoteStreams,
    isMainPeer
  };
};

export default useRoom; 
