import { Board } from '@onesy/api';

import { BaseService } from './base';

class BoardService extends BaseService<Board> {

  get paginationLimit() {
    return 15;
  }

  public constructor() {
    super('boards');
  }

}

export default new BoardService();
